import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, message } from "antd";

import ProcurementLogo from "assets/images/img/procurement-ai.png";
import OctoHelps from "assets/images/img/octo-helps.png";
import OctoUserLogin from "assets/images/img/octo-login-user.png";

import { useAppContext } from "context/appContext";
import { LS } from "utils/utils";
import { getUserSSO, setupAuthAPISession, userAuth } from "api/proxy/auth-api";

import { Button, Input } from "components/form";

import Styles from "./login.module.scss";
import { useSocketContext } from "api/core/context/websocket-api-context";
import { useEffect } from "react";

const LoginPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { setIsAuthenticated, meData, setMeData } = useAppContext();
  const [submitting, setSubmitting] = useState(false);
  const [ssoUrl, setSsoUrl] = useState(null);
  const { api, adminApi } = useSocketContext();
  const [isSSOLogin, setIsSSOLogin] = useState(true);

  const handleSubmit = async (data) => {
    setSubmitting(true);
    userAuth(
      {
        user: data.username,
        pass: data.password,
        ...(data.confirm && {
          pass2: data.confirm,
        }),
        ...(data.secret && { secret: data.secret }),
      },
      (msg, error) => {
        if (msg) {
          setMeData(msg);
          if (msg.sid) {
            LS.set("session", msg.sid);
            LS.set("user", JSON.stringify(msg));
            setIsAuthenticated(true);
            navigate("/");
          }
        } else if (error) {
          message.open({
            type: "error",
            content: error,
          });
          setIsAuthenticated(false);
          LS.delete("session");
          LS.delete("user");
          navigate("/login");
        }
      }
    );
    setSubmitting(false);
  };

  useEffect(() => {
    if (api || adminApi) {
      setupAuthAPISession(api, setMeData);
      getUserSSO((url) => {
        setSsoUrl(url);
      });
    }
  }, [api, adminApi, setMeData]);

  const attemptSSOLogin = async () => {
    window.open(ssoUrl, "_self");
  };

  return (
    <div id="loginMainContainer" className={Styles["container"]}>
      <div className={Styles["wrapper-container"]}>
        <div className={Styles["left-section"]}>
          {!isSSOLogin && <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', height: "100%", flexDirection: "column" }}>
            <img
              src={OctoHelps}
              loading="lazy"
              width={280}
              height={130}
              alt="final-logo"
            />
            <img
              src={OctoUserLogin}
              loading="lazy"
              width={280}
              height={150}
              alt="final-logo"
            />
          </div>}
          {isSSOLogin && <div>
            <img
              src={OctoHelps}
              loading="lazy"
              width={150}
              height={80}
              className={Styles.octoHelps}
              alt="final-logo"
            />
            <img
              src={ProcurementLogo}
              loading="lazy"
              width={180}
              height={50}
              className={Styles.logoResponsiveMobile}
              alt="final-logo"
            />
            <div className={Styles["welcome-wrapper"]}>
              <img
                src={ProcurementLogo}
                loading="lazy"
                width={350}
                height={110}
                alt="final-logo"
              />
              {/* <div className={Styles["title-container"]}>
                <p className={Styles["title"]}>Welcome</p>
                <p className={Styles["sub-title"]}>
                  Sign <span style={{ color: "#ff9900" }}>i</span>n to <br />
                  cont<span style={{ color: "#0A91F2" }}>i</span>nue access
                </p>
              </div>*/}
            </div>
          </div>}
        </div>

        <div className={Styles["right-section"]}>
          <p className={Styles["sign-in"]}>Sign In</p>
          {isSSOLogin ? (
            <Form
              form={form}
              className={Styles.form}
              data-testid="login-form"
              onFinish={handleSubmit}
              disabled={submitting}
              requiredMark={false}
              labelCol={{ span: 24 }}
              validateTrigger={["onBlur", "onChange"]}
            >
              <Form.Item
                label={<p className={Styles["label-text"]}>SSO Username</p>}
                name="ssoUsername"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input
                  className={Styles["input-field"]}
                  placeholder="Enter your email address"
                  data-testid="email-field"
                  height="default"
                />
              </Form.Item>
              <Button
                className={Styles["login-button"]}
                htmlType="submit"
                disabled={!ssoUrl}
                onClick={attemptSSOLogin}
                loading={submitting}
              >
                Login with SSO
              </Button>
            </Form>
          ) : (
            <Form
              form={form}
              className={Styles.form}
              data-testid="login-form"
              onFinish={handleSubmit}
              disabled={submitting}
              requiredMark={false}
              labelCol={{ span: 24 }}
              validateTrigger={["onBlur", "onChange"]}
            >
              <Form.Item
                label={<p className={Styles["label-text"]}>Username</p>}
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input
                  className={Styles["input-field"]}
                  placeholder="Enter your email address"
                  data-testid="email-field"
                  height="default"
                />
              </Form.Item>
              <Form.Item
                label={<p className={Styles["label-text"]}>Password</p>}
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input
                  type="password"
                  className={Styles["input-field"]}
                  placeholder="Enter your email password"
                  data-testid="pwd-field"
                  height="default"
                />
              </Form.Item>
              {meData?.admin_init && (
                <>
                  <Form.Item
                    label={
                      <p className={Styles["label-text"]}>Confirm Password</p>
                    }
                    name="confirm"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The new password that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      type="password"
                      className={Styles["input-field"]}
                      placeholder="Enter your email password"
                      height="default"
                    />
                  </Form.Item>
                  <Form.Item
                    label={<p className={Styles["label-text"]}>Org Secret</p>}
                    name="secret"
                    rules={[
                      {
                        required: true,
                        message: "Please input your org secret!",
                      },
                    ]}
                  >
                    <Input
                      className={Styles["input-field"]}
                      placeholder="Enter your org secret"
                      height="default"
                    />
                  </Form.Item>
                </>
              )}
              <Button
                className={Styles["login-button"]}
                htmlType="submit"
                loading={submitting}
              >
                Login
              </Button>
            </Form>
          )}
          <Button
            id="login-type-btn"
            className={Styles["login-type-button"]}
            htmlType="text"
            // disabled={!ssoUrl}
            onClick={() => setIsSSOLogin((isSSOLogin) => !isSSOLogin)}
            loading={submitting}
          >
            {isSSOLogin ? "Login with username" : "Login with SSO"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
